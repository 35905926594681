const config = require('./config.yaml');
const CMenu = require('../../index.js');

/**
 * Реализует логику варианта horizontal-cols компонента menu
 */
class CMenu_horizontalCols extends CMenu {
	constructor() {
		super();
		this.config = config;
		// Тип меню для определения класса
		this.menuType = 'top';
		// Имя варианта для создания глобальных событий
		this.eventNameVariantPart = 'HorizontalCols';
	}

	init() {
		// Меню
		this.$menu = $('.js-top-nav--horizontal-cols');

		// Если меню на странице существует
		if (this.$menu.length) {
			// Все элементы ссылок в меню
			this.$links = this.$menu.find('.js-top-link');
			// Все ссылки с подменю
			this.$linksWithSub = this.$menu.find('.has-submenu > .js-top-link-lvl1');
			// Класс списков (выпадашек)
			this.listClass = '.js-sub-space';

			// Установить события, на которые раскрывать / закрывать списки меню
			this.setOpenHideEvents();
			// По установленному событию на ссылки с подменю инициализировать раскрытие
			this.$linksWithSub.on(this.openEvents, $.proxy(this, 'onOpen'));

			// Если меню необходимо делать фиксируемым
			if (this.config.fixedMenu) {
				// Инициализировать наблюдателя состояния фиксирующегося меню
				this.initFixMenuWatcher();
			}
			$('.js-top-list-lvl4').each(function () {
				let $this = $(this);
				if ($this.find('.current').length) {
					$this.slideDown();
				}
			});
			$('.b-nav__trigger').on('click', function () {
				let $this = $(this);
				let open = 'open';
				let isOpen = $this.hasClass(open);
				let $menu4 = $this.parents('.b-nav__item--lvl3 .b-nav__link-wrapper').siblings('.js-top-list-lvl4');
				if (isOpen) {
					$menu4.slideUp();
					$this.removeClass(open);
					$this.parents('.b-nav__item--lvl3 .b-nav__link-wrapper').removeClass(open);
				} else {
					$menu4.slideDown();
					$this.addClass(open);
					$this.parents('.b-nav__item--lvl3 .b-nav__link-wrapper').addClass(open);
				}

			});

		}
	}

	checkMenu() {
		setTimeout(() => {
			if ($('.js-top-link-lvl1.is-expand').length) {
				$('.l-header-preview__photo-wrapper').hide();
			} else {
				$('.l-header-preview__photo-wrapper').show();
			}
		});
	}


	/**
	 * Раскрытие списка
	 * @param  {Object} $element Элемент список
	 */
	open($element) {
		// Раскрытые списки на текущем уровне
		const $expandedSiblings = $element
			.closest(`.js-${this.menuType}-item`)
			.siblings()
			.find(`.js-${this.menuType}-link.is-expand`);
		// Эффект и продолжительность анимации
		const {openEffect, openDuration} = this.getOpenAnimation($element);

		this.checkMenu();

		if ($expandedSiblings.length) {
			// Закрыть списки на текущем уровне
			this.close($expandedSiblings, true);
		}

		// Если слушатель клика по документу не провешен
		if (!this.documentClickListen) {
			// Провесить слушателя клика по документу
			$(document).on(this.hideEvents, $.proxy(this, 'onDocumentListener'));
			this.documentClickListen = true;
		}

		// Раскрыть список
		$element
			.addClass('is-expand')
			.siblings(this.listClass)
			.velocity(openEffect, {
				duration: openDuration,
				// Стригерить глобальное событие начала раскрытия списка
				begin: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}OpenStart`, $(element)),
				// Стригерить глобальное событие завершения раскрытия списка
				complete: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}OpenEnd`, $(element))
			});
	}

	/**
	 * Закрытие списка
	 * @param  {Object}  $element      Элемент список
	 * @param  {Boolean} closeForNext  Делается ли закрытие списка перед открытием следующего
	 */
	close($element, closeForNext) {
		// Эффект и продолжительность анимации
		const {closeEffect, closeDuration} = this.getCloseAnimation($element, closeForNext);

		this.checkMenu();

		// Закрыть список
		$element
			.removeClass('is-expand')
			.siblings(this.listClass)
			.velocity(closeEffect, {
				duration: closeDuration,
				// Стригерить глобальное событие начала закрытия списка
				begin: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}CloseStart`, $(element)),
				// Стригерить глобальное событие завершения закрытия списка
				complete: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}CloseEnd`, $(element))
			});

		// Если слушатель клика по документу провешен и у всех пунктов списки не раскрыты
		// и закрытие делается не перед открытием следующего
		if (this.documentClickListen && !this.$links.hasClass('is-expand') && !closeForNext) {
			// Снять слушателя клика по документу
			$(document).off(this.hideEvents, $.proxy(this, 'onDocumentListener'));
			this.documentClickListen = false;
		}
	}

	/**
	 * Получение эффекта и продолжительности анимации раскрытия
	 */
	getOpenAnimation() {
		return {
			openEffect: this.config.animation.open.transition,
			openDuration: this.config.animation.open.duration
		};
	}

	/**
	 * Получение эффекта и продолжительности анимации закрытия
	 */
	getCloseAnimation() {
		return {
			closeEffect: this.config.animation.close.transition,
			closeDuration: this.config.animation.close.duration
		};
	}

	/**
	 * Обработчик события клика / тапа / наведения курсора на документ
	 * Создаем свой обработчик вместо унаследованного,
	 * иначае при нахождении на странице нескольких вариантов, которые также унаследовали метод
	 * и для одного из них сделать $.off(), то для всех остальных также отключаюется, т.е наследуют
	 * @param  {Object} event событие
	 */
	onDocumentListener(event) {
		// Обращаемся к родительскому методу
		super.onDocumentListener(event);
	}
}

AR.waitComponents([], () => {
	const cMenu_horizontalCols = new CMenu_horizontalCols();
	// Вызов метода, инициализирующего все существующие события
	cMenu_horizontalCols.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cMenu_horizontalCols, 'cMenu_horizontalCols');
});
