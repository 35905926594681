const svgPanZoom = require('svg-pan-zoom');
const $map = $('.js-map');
const cookie = require('cookie_js');
const mapHelpCookieName = 'shown_map_help';

if ($map.length) {
	AR.events.on('svgRenderedAll', initPage);
}

function initPage() {
	initMap();
	initRegionChanger();
	if (!cookie(mapHelpCookieName)) {
		initHelper();
	}
	$map.find('.js-preloader').fadeOut(300);
}

function initHelper() {
	const $help = $map.find('.js-map-help');
	const $top = $map.find('.js-map-top');

	$map.addClass('is-help');

	$($top, $help).on('mousedown touchstart', () => {
		$help.velocity('fadeOut', {
			duration: 200,
			complete: () => {
				setCookie();
				$map.removeClass('is-help');
			}
		});
	});
}

function setCookie() {
	cookie.set(mapHelpCookieName, 'true', {path: '/', expires: 365});
}

function initRegionChanger() {
	const $svg = $map.find('#map-svg');
	const $buttons = $map.find('.map-filter__button');
	const getActive = (i, elem) => $(elem).hasClass('is-active');

	$buttons.on('click', function (e) {
		const $currentButton = $(e.target);

		if ($currentButton.hasClass('is-active')) {
			return false;
		}

		const $prevButton = $buttons.filter(getActive);
		const id = $currentButton.data('tab');
		const prevId = $prevButton.data('tab');

		$currentButton.addClass('is-active');
		$prevButton.removeClass('is-active');

		$svg.removeClass(`group-${prevId}`);
		$svg.addClass(`group-${id}`);
	});
}

function initMap() {
	const beforePan = function (oldPan, newPan) {
		var stopHorizontal = false,
			stopVertical = false,
			gutterWidth = window.innerWidth / 2,
			gutterHeight = window.innerHeight / 100 * 70,
			sizes = this.getSizes(),
			leftLimit = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + gutterWidth,
			rightLimit = sizes.width - gutterWidth - (sizes.viewBox.x * sizes.realZoom),
			topLimit = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + gutterHeight,
			bottomLimit = sizes.height - gutterHeight - (sizes.viewBox.y * sizes.realZoom);

		return {
			x: Math.max(leftLimit, Math.min(rightLimit, newPan.x)),
			y: Math.max(topLimit, Math.min(bottomLimit, newPan.y))
		};
	};
	const svgPan = svgPanZoom('.js-map-svg svg', {
		zoomEnabled: false,
		dblClickZoomEnabled: false,
		mouseWheelZoomEnabled: false,
		fit: false,
		center: false,
		beforePan: beforePan
	});
}
