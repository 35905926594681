module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'social-services/static-in-line' && templateVars.config ?
				templateVars.config :
				{
	"animationClose": "transition.slideDownOut",
	"animationCloseDuration": 200,
	"animationOpen": "transition.slideUpIn",
	"animationOpenDuration": 200,
	"links": {
		"ru": [
			{
				"modifier": "youtube",
				"targetBlank": true,
				"url": "https://www.youtube.com/user/EVRAZplc",
				"text": "YouTube"
			}
		],
		"en": [
			{
				"modifier": "youtube",
				"targetBlank": true,
				"url": "https://www.youtube.com/user/EVRAZplc",
				"text": "YouTube"
			}
		]
	}
};