const CTooltips = require('../../index.js');
const config = require('./config.yaml');

class CTooltips_base extends CTooltips {
	constructor() {
		super();
		this.$tooltips = $('.b-tooltip');
		this.config = config;
		this.tooltipsterConfig = this.config.tooltipster;
		this.isSvg = false;
		this.$tooltipFootnotes = null;
	}

	/**
	 * Инициализация
	 */
	init() {
		if (this.$tooltips.length) {
			// Создать подсказки
			this.createTooltips(this.$tooltips);
		}

		// Инициализировать подсказки для SVG (async loaded)
		AR.events.on('svgRenderedAll', $elements => {
			const tooltipsInSvg = [];

			// Для каждого SVG
			$elements.each((i, elem) => {
				const $svgTooltip = $(elem).find('.b-tooltip');

				// Если в SVG есть элемент, для которого следует создать подсказку
				if ($svgTooltip.length) {
					if ($svgTooltip.length > 1) {
						tooltipsInSvg.push(...$svgTooltip);
					} else {
						tooltipsInSvg.push($svgTooltip);
					}
				}
			});

			if (tooltipsInSvg.length) {
				// Создать подсказки
				this.createTooltips($(tooltipsInSvg));
				// Пересоздать сноски
				this.rebuildSups();
			}
		});

		// Инициализировать подсказки в модалке при его раксрытии
		this._onZoomReinit();

		// Пересоздать сноски на событие onRebuildSup
		AR.events.on('onRebuildSup', () => this.rebuildSups());
	}

	/**
	 * Создание тултипа
	 * @param  {Object} $tooltips         Элементы, на которых будут выводиться подсказки
	 * @param  {Object} [customConfig={}] Кастомные конфигурация подсказки
	 * @param  {Boolean} [sup=true]       Флаг создания сноски
	 */
	createTooltips($tooltips, customConfig = {}, sup = true, makeIconInSvg = true) {
		$tooltips.each((i, elem) => {
			const $elem = $(elem);
			// Объединение дефолтного и кастомного конфигов
			let tooltipConfig = $.extend({}, this.tooltipsterConfig, customConfig);

			// Дата атрибуты элемента
			const dataAttributes = $elem.data();
			// Флаг SVG
			this.isSvg = $elem.closest('svg').length ? true : false;
			// Конечный конфиг
			tooltipConfig = this._createBaseConfig(tooltipConfig, dataAttributes);

			// Непосредственно создание тултипа
			$elem.tooltipster(tooltipConfig);

			// Проверка отмены создания иконки
			const noIcon = $elem.data('noicon');
			if (this.isSvg && makeIconInSvg && !noIcon) {
				this.createTooltipIcon($elem);
			}

			if (sup) {
				// Создать сноску
				this.createSup($elem, i);
			}
		});
	}

	/**
	 * Создание базового / конечного конфига
	 * @param  {Object} config         Объединенный конфиг (дефолтный, кастомный)
	 * @param  {Object} dataAttributes Дата атрибуты элемента
	 * @return {Object}                Собранный конфиг
	 */
	_createBaseConfig(config, dataAttributes) {
		// Копирование конфига(слияние с пустым), чтобы не происходило наследования
		const tempConfig = $.extend({}, config);
		const newConfig = $.extend({}, config);

		// Вызывается при создании инстанса подсказки
		newConfig['functionInit'] = (instance, helper) => {
			this.onInit(instance, helper, config['functionInit']);

			// Если не задан элемент, из которого брать контент для подсказки,
			// то брать из элемента внутри цели, на который будет вешается тултип
			if (!config.content) {
				const dataContentSelector = $(helper.origin).data('content');
				let $content = '';

				// Если тултипу задан дата атрибут с селектором кастомного контента,
				// то взять этот элемент с контентом
				if (dataContentSelector) {
					$content = $(dataContentSelector);
				} else {
					$content = $(helper.origin).find('.b-tooltip__text').html();
				}

				instance.content($content);
			}
		};

		// Если в дата атрибутах передана сторона-приоритет, то выставить её
		if (dataAttributes.side) {
			newConfig.side = dataAttributes.side;
		}

		if (dataAttributes.maxWidth) {
			newConfig.maxWidth = dataAttributes.maxWidth;
		}

		if (dataAttributes.minWidth) {
			newConfig.minWidth = dataAttributes.minWidth;
		}

		// Вызывается перед выводом подсказки
		newConfig['functionBefore'] = (instance, helper) => {
			this.onBefore(instance, helper, tempConfig['functionBefore']);
		};

		// Вызывается, когда элемент-подсказка добавлена в DOM
		newConfig['functionReady'] = (instance, helper) => {
			this.onReady(instance, helper, tempConfig['functionReady']);
		};

		// Вызывается после закрытия подсказки
		newConfig['functionAfter'] = (instance, helper) => {
			this.onAfter(instance, helper, tempConfig['functionAfter']);
		};

		// Вызывается при позиционировании подсказки.
		// Возвращает координаты, размеры, по-которым выводится подсказка
		newConfig['functionPosition'] = (instance, helper, position) => {
			return this.onPosition(instance, helper, position, tempConfig['functionPosition']);
		};

		return newConfig;
	}

	/**
	 * Вызывается при создании инстанса тултипа
	 * @param  {Object} instance        Инстанс тултипстера
	 * @param  {Object} helper          Вспомогательные значения тултипстера
	 * @param  {Function} customHandler Кастомная функция
	 */
	onInit(instance, helper, customHandler) {
		AR.events.emit('onTooltipInit', $(helper.origin));

		if (typeof customHandler == 'function') {
			customHandler(instance, helper);
		}
	}

	/**
	 * Вызывается перед выводом подсказки
	 * @param  {Object} instance        Инстанс тултипстера
	 * @param  {Object} helper          Вспомогательные значения тултипстера
	 * @param  {Function} customHandler Кастомная функция
	 */
	onBefore(instance, helper, customHandler) {
		AR.events.emit('onTooltipBefore', instance, helper);

		if (typeof customHandler == 'function') {
			customHandler(instance, helper);
		}
	}

	/**
	 * Вызывается, когда элемент-подсказка добавлен в DOM
	 * @param  {Object} instance        Инстанс тултипстера
	 * @param  {Object} helper          Вспомогательные значения тултипстера
	 * @param  {Function} customHandler Кастомная функция
	 */
	onReady(instance, helper, customHandler) {
		AR.events.emit('onTooltipReady', instance, helper);

		if (typeof customHandler == 'function') {
			customHandler(instance, helper);
		}
	}

	/**
	 * Вызывается после закрытия подсказки
	 * @param  {Object} instance        Инстанс тултипстера
	 * @param  {Object} helper          Вспомогательные значения тултипстера
	 * @param  {Function} customHandler Кастомная функция
	 */
	onAfter(instance, helper, customHandler) {
		AR.events.emit('onTooltipAfter', instance, helper);

		if (typeof customHandler == 'function') {
			customHandler(instance, helper);
		}
	}

	/**
	 * Вызывается при позиционировании подсказки.
	 * Возвращает координаты, размеры, по-которым выводится подсказка
	 * @param  {Object} instance        Инстанс тултипстера
	 * @param  {Object} helper          Вспомогательные значения тултипстера
	 * @param  {Function} customHandler Кастомная функция
	 * @return {Object}                 Значения позиционирования
	 */
	onPosition(instance, helper, position, customHandler) {
		// Если не указан дата атрибут с приоритетом сторон
		if (!$(helper.origin).data('side')) {
			// Получение новых значений позиционирования подсказки, если потребуется
			position = this.getTooltipHorizontalPosition(helper, position) || position;
		}

		if (typeof customHandler == 'function') {
			position = customHandler(instance, helper, position);
		}

		return position;
	}

	/**
	 * Проверить на вмещение подсказки после последней строки
	 * @param  {Object} helper   Вспомогательные значения тултипстера
	 * @param  {Object} lastLine Размеры, координаты последней строки
	 * @return {Object}          Значения для вмещения подсказки после последней строки
	 */
	checkFitRightWhenLeft(helper, lastLine) {
		// Копия подсказки
		const $tooltipClone = $(helper.tooltip).clone();
		// Пространство после последней строки для подсказки
		const originRightSideSpace = helper.geo.window.size.width - this.tooltipsterConfig.distance - lastLine.right;

		$tooltipClone
			.css({
				width: `${originRightSideSpace}px`,
				height: 'auto',
				overflow: 'auto',
				display: 'block'
			})
			.addClass('tooltipster-right');

		// Добавить копию подсказки во временный блок и добавить его в body
		const $container = $('<div class="tooltipster-ruler tooltipster-ruler--recalc"></div>')
			.append($tooltipClone)
			.appendTo('body');
		// Подсказка
		const tooltip = $container.find('.tooltipster-base')[0];
		// Блок с контентом тултипа
		const content = $container.find('.tooltipster-content')[0];
		// Размеры контентного блока и его позиция относительно окна
		const contentBcr = content.getBoundingClientRect();
		const result = {
			// Проверка на существоание скролла
			fit: contentBcr.width >= content.scrollWidth - 1,
			width: originRightSideSpace,
			height: tooltip.getBoundingClientRect().height
		};

		// Удалить временный блок
		$container.remove();

		return result;
	}

	/**
	 * Получить новые значения позиционирования подсказки (слева / справа)
	 * @param {Object} helper    Вспомогательные значения тултипстера
	 * @param {Object} position  Значения позиционирования
	 * @return {Object, Boolean} Значения позиционирования / false
	 */
	getTooltipHorizontalPosition(helper, position) {
		// Размеры, координаты для каждой строки цели тултипа
		const originRects = helper.origin.getClientRects();

		// Если строк больше одной
		if (originRects.length > 1) {
			// Цель тултипа
			const $origin = $(helper.origin);
			// Размеры, координаты первой и последней строки
			const [firstLine, lastLine] = [originRects[0], originRects[originRects.length - 1]];
			// Строка, относителной которой нужно позиционировать подсказку
			let targetLine;

			// Если тултипстер решил вывести подсказку справа
			if (position.side == 'right') {
				// Строкой-целью задать последнуюю строку
				targetLine = lastLine;
				// offset подсказки по горизонтали
				// (offset правой грани последней строки + расстояние между целью и подсказкой)
				position.coord.left = targetLine.right + this.tooltipsterConfig.distance;
			} else { // Иначе, если с любой другой стороны
				// Значения для вмещения подсказки после последней строки
				const rightSideFitResults = this.checkFitRightWhenLeft(helper, lastLine);

				// Если подсказка может помещена после последней строки
				if (rightSideFitResults.fit) {
					// Строкой-целью задать последнуюю строку
					targetLine = lastLine;
					// Новые размеры подсказки
					position.size = {
						width: rightSideFitResults.width,
						height: rightSideFitResults.height
					};
					// Задать сторону вывода тултипстера
					position.side = 'right';
					// offset подсказки по горизонтали
					// (offset правой грани последней строки + расстояние между целью и подсказкой)
					position.coord.left = targetLine.right + this.tooltipsterConfig.distance;
				} else if (position.side == 'left') { // Иначе, если тултипстер решил вывести подсказку слева
					// Строкой-целью задать первую строку
					targetLine = firstLine;
					// offset подсказки по горизонтали
					// (offset левой грани первой строки - ширина подсказки - расстояние между целью и подсказкой)
					position.coord.left = targetLine.left - position.size.width - this.tooltipsterConfig.distance;
				}
			}

			// Если сторона вывода тултипстера слева или справа
			if ((position.side == 'right' || position.side == 'left')) {
				// offset подсказки по вертикали
				position.coord.top += (targetLine.top + targetLine.height / 2) - (position.coord.top + position.size.height / 2);
				// Положение стрелки подсказки
				position.target = position.coord.top + (position.size.height / 2);

				// Если offset по вертикали отрицательный
				if (position.coord.top < 0) {
					// Задать равным нулю
					position.coord.top = 0;
				}

				return position;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	/**
	 * Иницализация подсказок в модалке
	 */
	_onZoomReinit() {
		// При раскрытии модалки
		$(document).bind('cbox_complete', () => {
			const $tooltips = $('#cboxLoadedContent').find('.b-tooltip');

			if ($tooltips.length) {
				// Создать подсказки для элементов в модалке
				this.createTooltips($tooltips, {}, false, false);
			}
		});
	}

	/**
	 * Создание иконки индиактор для SVG (показать, что элемент является тултипом)
	 * @param  {Object} $elem Элемент, которому нужно добавить иконку
	 */
	createTooltipIcon($elem) {
		const iconCode = this.config.svgIcon;
		const iconElement = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
		iconElement.setAttribute('class', 'b-tooltip__svg-icon');

		$elem[0].appendChild(iconElement);

		// innerHTML not working in IE :c
		$elem.find('.b-tooltip__svg-icon').html(iconCode);
	}
	/**
	 * Создание сносок для печатной версии
	 * @param  {Object} $elem Элемент, на котором будет выводиться подсказка
	 * @param  {Number} index Индекс элемента
	 */
	createSup($elem, index) {
		const $tooltipNumber = $elem.find('.b-tooltip-number');
		const supIndex = index + 1;

		if (!this.$tooltipFootnotes) {
			// Добавление блока для сносок тултипов
			this.$tooltipFootnotes = $('<div class="b-tooltips-footnotes"></div>').appendTo('.l-page__content');
		}

		if ($tooltipNumber.length) {
			$tooltipNumber.text(supIndex);
		} else {
			let supElement;

			// Если подсказку нужно создать внутри SVG
			if (this.isSvg) {
				const sup = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
				sup.setAttribute('class', 'b-tooltip-number');
				sup.setAttribute('baseline-shift', 'super');
				sup.textContent = supIndex;

				supElement = sup;
			} else {
				supElement = `<sup class="b-tooltip-number">${(supIndex)}</sup>`;
			}

			$elem.append(supElement);
		}

		this.$tooltipFootnotes.append(`<p class="b-footnotes"><sup>${(supIndex)}</sup> ${$elem.find('.b-tooltip__text').text()}</p>`);
	}

	/**
	 * Обновить сноски
	 */
	rebuildSups() {
		// Опустошить блок со сносками для тултипов
		this.$tooltipFootnotes.empty();
		// Обновить переменную с тултипами
		this.$tooltips = $('.b-tooltip');

		this.$tooltips.each((i, elem) => {
			this.createSup($(elem), i);
		});
	}
}

const cTooltips_base = new CTooltips_base;

cTooltips_base.init();

AR.pushComponent(cTooltips_base, 'cTooltips_base');
