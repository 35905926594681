module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'tour/base' && templateVars.config ?
				templateVars.config :
				{
	"skipButton": {
		"show": true,
		"template": "<button class=\"hopscotch-nav-button next tour-skip-button\">${skipButtonText}</button>"
	},
	"tours": {
		"en": {
			"logo": {
				"id": "topNavLogo",
				"lockPage": true,
				"isTourBubble": false,
				"steps": [
					{
						"target": ".l-header-top-line__logo",
						"placement": "bottom",
						"fixedElement": false,
						"additionaClasses": "site-tour",
						"title": "Click the logo to go to the main page of the website.",
						"showNextButton": false
					}
				]
			},
			"buttonsPanelTools": {
				"id": "buttonsPanel",
				"lockPage": true,
				"isTourBubble": false,
				"steps": [
					{
						"target": ".b-buttons-panel--tools",
						"placement": "bottom",
						"fixedElement": false,
						"additionaClasses": "site-tour",
						"title": "Use the toolbar to look through the interactive report, work with it, and navigate pages.",
						"showNextButton": false,
						"xOffset": -243,
						"arrowOffset": 240
					}
				]
			},
			"pageNav": {
				"id": "page_nav",
				"lockPage": true,
				"isTourBubble": false,
				"steps": [
					{
						"target": ".l-page__nav-controls",
						"placement": "left",
						"fixedElement": false,
						"additionaClasses": "site-tour",
						"title": "To view the report, use the navigation items.",
						"showNextButton": false,
						"yOffset": -2
					}
				]
			}
		},
		"ru": {
			"logo": {
				"id": "topNavLogo",
				"lockPage": true,
				"isTourBubble": false,
				"steps": [
					{
						"target": ".l-header-top-line__logo",
						"placement": "bottom",
						"fixedElement": false,
						"additionaClasses": "site-tour",
						"title": "Нажав на логотип, Вы попадете на главную страницу сайта.",
						"showNextButton": false
					}
				]
			},
			"buttonsPanelTools": {
				"id": "buttonsPanel",
				"lockPage": true,
				"isTourBubble": false,
				"steps": [
					{
						"target": ".b-buttons-panel--tools",
						"placement": "bottom",
						"fixedElement": false,
						"additionaClasses": "site-tour",
						"title": "Используйте панель инструментов для работы с интерактивным отчетом.",
						"showNextButton": false,
						"xOffset": -243,
						"arrowOffset": 240
					}
				]
			},
			"pageNav": {
				"id": "page_nav",
				"lockPage": true,
				"isTourBubble": false,
				"steps": [
					{
						"target": ".l-page__nav-controls",
						"placement": "left",
						"fixedElement": false,
						"additionaClasses": "site-tour",
						"title": "Для переключения между страницами отчета воспользуйтесь навигацией.",
						"showNextButton": false,
						"yOffset": -2
					}
				]
			}
		}
	}
};