// noinspection JSUnusedLocalSymbols
const text = require('./text.yaml')[templateVars.lang];
const CMainPage = require('../../index.js');

class CMainPageBase extends CMainPage {
	constructor() {
		super();
		this._countSlides = document.querySelectorAll('.main__item[data-num]').length;
		this._currentSlide = 0;
		this._slideAvailable = true;
		this._wheelAvailable = true;
		this._sliderTimeoutTimer = 0;
	}
	init() {
		if (this._countSlides < 1) {
			return;
		}
		this.loadImages();
		this.addListeners();
	}
	loadImages() {
		let imagePromises = [];
		[
			'/images/charts/book.svg',
			'/images/charts/geopin.svg',
			'/images/charts/arrow.svg',
			'/images/main/bisiness.png',
			'/images/main/ceo.png',
			'/images/main/Chairman.jpg',
			'/images/main/Digital.jpg',
			'/images/main/FAQ.png',
			'/images/main/KeyPerformance.jpg',
			'/images/main/operation.jpg',
			'/images/main/Risk.jpg',
			'/images/main/RnD.jpg',
		].forEach((src) => {
			let promise = new Promise((resolve, reject) => {
				let image = new Image();
				image.src = src;
				image.onload = () => {
					resolve(image);
				};
				image.onerror = () => reject(new Error('Load image error'));
			});
			imagePromises.push(promise);
		});
		Promise.all(imagePromises).then(() => {
			document.getElementById('main_page').classList.remove('preloader');
		});
	}
	addListeners() {
		this.addEventListenerList(document.querySelectorAll('.main__item[data-num]'), 'click', (e) => {
			if (window.innerWidth < 1024 || window.innerWidth < 620) {
				return true;
			}
			document.body.style['overflow-y'] = 'hidden';
			document.querySelector('.detail-popup').style.display = 'block';
			setTimeout(() => {
				document.querySelector('.detail-popup').classList.add('__popup-visible');
				const data_num = this.getDataNum(e.target);
				this.openSlide(data_num);
			});
		});
		this.addEventListenerList(document.querySelectorAll('[data-indication-container] [data-num]'), 'click', (e) => {
			const data_num = this.getDataNum(e.target);
			this.openSlide(data_num);
		});
		this.addEventListenerList(document.querySelectorAll('[data-list-container] [data-num]'), 'click', (e) => {
			const data_num = this.getDataNum(e.target);
			this.openSlide(data_num);
		});
		document.querySelector('[data-close-button]').addEventListener('click', () => {
			document.body.style['overflow-y'] = 'auto';
			document.querySelector('.detail-popup').classList.remove('__popup-visible');
			setTimeout(() => {
				document.querySelector('.detail-popup').style.display = 'none';
			}, 500); // transition duration
		});
		this.addEventListenerList(document.querySelectorAll('[data-page-change]'), 'click', (e) => {
			let change = e.target.getAttribute('data-page-change');
			if (change === null) {
				change = e.target.parentNode.getAttribute('data-page-change');
			}
			if (parseInt(change) === 1) {
				this.slideUp();
			} else {
				this.slideDown();
			}
		});
		document.querySelector('.detail-popup__wrap').addEventListener('wheel', (e) => {
			if (this._sliderTimeoutTimer > 0) {
				clearTimeout(this._sliderTimeoutTimer);
			}
			this._sliderTimeoutTimer = setTimeout(() => {
				this._wheelAvailable = true;
			}, 300);
			if (!this._wheelAvailable) {
				return;
			}
			this._wheelAvailable = false;
			if (e.deltaY >= 0) {
				this.slideUp();
			} else {
				this.slideDown();
			}
		});
		window.addEventListener('resize', () => {
			if (this._currentSlide < 1) {
				return true;
			}
			this.resizeSlide();
		});
	}
	getDataNum(node) {
		let dataNum = node.getAttribute('data-num');
		if (dataNum === null) {
			dataNum = node.parentNode.getAttribute('data-num');
		}
		return dataNum;
	}

	/**
	 * получение порядкового номера слайда
	 * @param $elem
	 */
	getCounter($elem) {
		if ($elem.length == 0) {
			return;
		}
		let _class = $elem.attr('class').split(' ');
		let result = false;

		$(_class).each(function () {
			let reg = this.match(/__item-(.*)/i);
			if (reg && reg[1]) {
				result = reg[1];
			}
		});

		return result;
	}

	clearStyles() {
		setTimeout(() => {
			$('.detail-popup__item:not(.current)')
				.attr('style', '').removeClass('__animation');

			$('.detail-popup__item.current')
				.css({'z-index': '', transform: '', opacity: ''});

			$('.detail-popup__item.current').removeClass('current');

			$('.detail-popup__item-img').attr('style', '');
		}, 0);
	}

	openSlide(number) {
		if (templateVars.site == 'csr') {
			let aTime = 1500;
			let prevCounter;
			let nowCounter;
			let $prev = $(document.querySelector('[data-num="' + this._currentSlide + '"].detail-popup__item'));
			let $now = $(document.querySelector('[data-num="' + number + '"].detail-popup__item'));
			if (!this._slideAvailable) {
				return true;
			}

			$('.detail-popup__item-link').css({opacity: 0});
			$('.detail-popup__item').removeClass('__animation').css({transition: 'unset'});
			$('.js-hide-me').css({'z-index': 12});

			this._slideAvailable = false;
			if (this._currentSlide > 0) {
				prevCounter = this.getCounter($prev);
			}
			const slideToClose = this._currentSlide;
			{//WORK
				// Open
				nowCounter = this.getCounter($now);

				if (prevCounter) {

					$('[data-indication-container] [data-num],[data-list-container] [data-num]').removeClass('__active');
					$now.find('.js-hide-me').velocity({opacity: [1, 0]}, aTime);
					$prev.find('.js-hide-me').velocity({opacity: [0, 1]}, aTime);
					$prev.show().addClass('__animation');
					$now.show().addClass('current __animation');
					let nowAnimation = {
						top: ['0%', '-10%'],
						height: ['100%', '0%']
					};
					if (prevCounter < nowCounter) {//прямой порядок текущее верхнее
						$now.css({transform: 'translateY(-100%)'});
						$prev.find('.detail-popup__item-link').css({opacity: 0});//->
					}
					if (prevCounter > nowCounter) {// обратный порядок
						$prev.css({transform: 'translateY(-100%)'});
						$('.detail-popup__item-link').css({opacity: 0});
						$now.css({'z-index': 11});
					}

					if ($('html').hasClass('browser-ie')) {
						nowAnimation = {
							top: ['50%', '-10%'],
							height: ['100%', '0%']
						};
					}

					$prev.find('.detail-popup__item-img').velocity({
						translateY: ['-40%', '-50%'],
						opacity: 0
					}, aTime);

					$now.find('.detail-popup__item-img').css({
						'z-index': 11,
						transform: 'unset',
						top: '-50%',
						opacity: 0
					}).velocity(nowAnimation, {
						duration: aTime,
						begin: () => {
							$now.find('.detail-popup__item-img').css({opacity: '1'});
						},
						complete: () => {
							$('[data-indication-container] [data-num="' + number + '"],' +
								'[data-list-container] [data-num="' + number + '"]').addClass('__active');
							$now.find('.detail-popup__item-link').velocity({opacity: 1}, aTime / 4, () => {
								this._slideAvailable = true;
							});
							this.clearStyles();
						}
					});

				} else {
					$('[data-indication-container] [data-num="' + number + '"],' +
						'[data-list-container] [data-num="' + number + '"]').addClass('__active');
					this._slideAvailable = true;
					$('.detail-popup__item-link').css({opacity: 1});
				}

				document.querySelector('[data-popup-item-container] [data-num="' + number + '"]').style.display = 'flex';
				// Timeout for transition
				setTimeout(() => {
					document.querySelector('[data-popup-item-container] [data-num="' + number + '"]').classList.add('__animation');
				});
				this._currentSlide = parseInt(number);
				this.resizeSlide();

			}
		} else {
			if (!this._slideAvailable) {
				return true;
			}
			this._slideAvailable = false;
			if (this._currentSlide > 0) {
				document.querySelector('[data-indication-container] [data-num="' + this._currentSlide + '"]').classList.remove('__active');
				document.querySelector('[data-list-container] [data-num="' + this._currentSlide + '"]').classList.remove('__active');
				document.querySelector('[data-popup-item-container] [data-num="' + this._currentSlide + '"]').classList.remove('__animation');
				document.querySelector('[data-popup-item-container] [data-num="' + this._currentSlide + '"] .detail-popup__item-img').style.height = '';
				document.querySelector('[data-popup-item-container] [data-num="' + this._currentSlide + '"] .detail-popup__item-img').style.width = '';
			}
			const slideToClose = this._currentSlide;
			setTimeout(() => {
				if (slideToClose > 0) {
					document.querySelector('[data-popup-item-container] [data-num="' + slideToClose + '"]').style.display = 'none';
				}
				// Open
				document.querySelector('[data-indication-container] [data-num="' + number + '"]').classList.add('__active');
				document.querySelector('[data-list-container] [data-num="' + number + '"]').classList.add('__active');
				document.querySelector('[data-popup-item-container] [data-num="' + number + '"]').style.display = 'flex';
				// Timeout for transition
				setTimeout(() => {
					document.querySelector('[data-popup-item-container] [data-num="' + number + '"]').classList.add('__animation');
				});
				this._currentSlide = parseInt(number);
				this.resizeSlide();
				this._slideAvailable = true;
			}, 400);
		}

	}
	slideUp() {
		let slideToSwitch = this._currentSlide + 1;
		if (slideToSwitch > this._countSlides) {
			slideToSwitch = 1;
		}
		this.openSlide(slideToSwitch);
	}
	slideDown() {
		let slideToSwitch = this._currentSlide - 1;
		if (slideToSwitch < 1) {
			slideToSwitch = this._countSlides;
		}
		this.openSlide(slideToSwitch);
	}
	addEventListenerList(list, event, fn) {
		for (let i = 0, len = list.length; i < len; i++) {
			list[i].addEventListener(event, fn, false);
		}
	}
	resizeSlide() {
		const imgContainer = document.querySelector('[data-popup-item-container] [data-num="' + this._currentSlide + '"] .detail-popup__item-img');
		imgContainer.style.height = '';
		imgContainer.style.height = (Math.floor(imgContainer.offsetHeight / 2) * 2) + 'px';
		imgContainer.style.width = '';
		imgContainer.style.width = (Math.floor(imgContainer.offsetWidth / 2) * 2) + 'px';
	}
}

AR.waitComponents(['cPreloader_base'], () => {
	const component = new CMainPageBase();
	component.init();
	AR.pushComponent(component, 'main_page_base');
});
