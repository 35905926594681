const CForm = require('../../index.js');
const config = require('./config.yaml');
const text = require('./text.yaml')[templateVars.lang];

/**
 * Класс реализует логику для варианта компонента cForm_privacy
 */
class CForm_privacy extends CForm {
	constructor() {
		//вызываем конструктор родителя
		super();

		//форма
		this.$privacyForm = null;
		this.config = config;
	}

	/**
	 * Инициализация логики работы компонента
	 */
	init() {
		//берем формы со страницы
		this.$privacyForm = $('.js-form.js-form--privacy');

		//если формы найдены
		if (this.$privacyForm.length) {
			//инициализация обработки сабмита формы
			this.initFormSubmit(this.$privacyForm, config, text);
			this.addFileValidator();

			//провешивание на ресет формы обработчика с пробросом конфига варианта
			this.$privacyForm.on('reset', $.proxy(this, 'onResetForm', config));
			//инициализация валидации в форме с пробросом конфига варианта
			this.initValidation(this.$privacyForm, config);
			//инициализация полей с масками с пробросом конфига варианта
			this.initMaskPlugin(this.$privacyForm, config);

			this.initSmartPlaceholder(this.$privacyForm, config, text);

			const $upload = this.$privacyForm.find('.js-upload-file');
			const $uploadNameEl = this.$privacyForm.find('.js-upload-file-name');
			const $popup = this.$privacyForm.parents('[data-popup-id]');

			if ($upload.length && $uploadNameEl.length) {
				$upload.on('change', () => this.setFileName($upload, $uploadNameEl));
			}

			if ($popup.length) {
				this.initInteractiveWithPopup(this.$privacyForm, $popup, config);
			}

			this.initRadioButton();
		}
	}

	setFileName($upload, $uploadNameEl) {
		const name = $upload[0].files.length ? $upload[0].files[0].name : '';

		$uploadNameEl.text(name);
	}

	initRadioButton() {
		const $rbGroups = $('.js-form-rb');
		const $rbOther = $rbGroups.find('.js-rb-other-input');

		if ($rbGroups.length) {
			$rbOther.on('input', e => {
				const $input = $(e.currentTarget);
				const $radio = $input.siblings('.js-rb-other-rb');

				$radio.val($input.val());
				this.$privacyForm.parsley().validate('radio');
			});

			$rbGroups.each((i, elem) => {
				const $elem = $(elem);
				const $group = $elem.closest('.js-form-rb');
				const $mainRadioWrapper = $group.find('.b-form__rb:first-child');
				const $mainRadio = $mainRadioWrapper.find('input[type="radio"]');
				const $radios = $elem.find('input[type="radio"]');

				$radios.on('change', () => {
					$mainRadio.attr('data-parsley-required-message', text.errorRequired);
					$radios.off('change');

					if ($mainRadioWrapper.hasClass('is-error')) {
						$group.find('.parsley-errors-list li').html(text.errorRequired);
					}
				});
			});
		}
	}

	addFileValidator() {
		window.Parsley
			.addValidator('filemaxsize', {
				requirementType: 'string',
				validateString: function (value, requirement, parsleyInstance) {
					const file = parsleyInstance.$element[0].files;

					if (file.length == 0) {
						return true;
					}

					return file.length === 1 && file[0].size <= requirement;

				}
			})
			.addValidator('filemimetypes', {
				requirementType: 'string',
				validateString: function (value, requirement, parsleyInstance) {
					const file = parsleyInstance.$element[0].files;

					if (file.length == 0) {
						return true;
					}

					const allowedMimeTypes = requirement.replace(/\s/g, '').split(',');
					return allowedMimeTypes.indexOf(file[0].type) !== -1;
				}
			});
	}
}

AR.waitComponents(['cCaptcha_base', 'cNotification_base', 'cPopup_base'], () => {
	const cForm_privacy = new CForm_privacy();
	// Вызов метода со всеми событиями
	cForm_privacy.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cForm_privacy, 'cForm_privacy');
});
