module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'menu/horizontal-cols' && templateVars.config ?
				templateVars.config :
				{
	"titleParam": [
		"menuTitle",
		"shortTitle",
		"title"
	],
	"depthLevel": 4,
	"itemsNum": false,
	"hoverable": false,
	"hoverableDelay": 200,
	"columnsNum": 4,
	"columnClass": "col-lg-3 col-md-6",
	"disableCurrentLinks": false,
	"fixedMenu": false,
	"animation": {
		"open": {
			"transition": "transition.slideDownIn",
			"duration": 200
		},
		"close": {
			"transition": "transition.slideUpOut",
			"duration": 200
		}
	},
	"customLinksType": "menu-horizontal-cols"
};