const text = require('./text.yaml')[templateVars.lang];

require('dayjs/locale/ru');


/**
 * Реализует логику варианта base компонента header-place
 */
class HeaderPlace_base {
	constructor() {
		this.config = config;
		this.text = text;
	}

}

AR.waitComponents(['HeaderPlace_base'], () => {
	const HeaderPlace_base = new HeaderPlace_base();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cHistory_base, 'HeaderPlace_base');
});
