const config = require('./config.yaml');
const CPageNav = require('../../index.js');

/**
 * Реализует логику варианта buttons компонента page-nav
 */
class CPageNav_buttons extends CPageNav {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.$pagenavButtons = $('.js-pagenav-buttons');
		if (this.$pagenavButtons.length) {
			this.$upBtn = this.$pagenavButtons.find($('.js-scrollup'));
			this.$pagenavLink = this.$pagenavButtons.find($('.js-pagenav-link'));
			this.scrollTopValue = this.config.scrollTopValue;
			this.toggleShowUpButton();
			$(window).on('scroll', $.proxy(this, 'toggleShowUpButton'));
			this.$upBtn.on('click', $.proxy(this, 'scrollUp'));
			// $(document).on('keydown', $.proxy(this, 'checkKeyDown'));
			// $(document).on('touchstart', $.proxy(this, 'removeTouchClass'));
			// this.$upBtn.on('touchstart', $.proxy(this, 'touchStart'));
			// this.$pagenavLink.on('touchstart', $.proxy(this, 'touchStart'));
			this.addHelpKey();
		}
	}

	/**
	 * Установить состояние кнопки наверх
	 */
	toggleShowUpButton() {
		if ($(window).scrollTop() < this.scrollTopValue && !this.$upBtn.hasClass('b-pagenav__scrollup--disabled')) {
			this.$upBtn.addClass('b-pagenav__scrollup--disabled');
		} else if ($(window).scrollTop() > this.scrollTopValue && this.$upBtn.hasClass('b-pagenav__scrollup--disabled')) {
			this.$upBtn.removeClass('b-pagenav__scrollup--disabled');
		}
	}
}

AR.waitComponents([], () => {
	const cPageNav_buttons = new CPageNav_buttons();
	// Вызов метода, инициализирующего все существующие события
	cPageNav_buttons.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cPageNav_buttons, 'cPageNav_buttons');
});
