const config = require('./config.yaml');
const CSearchForm = require('../../index.js');

/**
 * Реализует логику варианта extendable компонента search-form
 */
class CSearchForm_extendable extends CSearchForm {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.$searchForm = $('.js-search--extendable');

		if (this.$searchForm.length) {
			this.$searchForm.each((i, elem) => {
				const $form = $(elem);
				const formObj = {
					$form: $form,
					$searchInput: $form.find('.js-search-input'),
					$searchButton: $form.find('.js-search-button'),
					$searchLabel: $form.find('.js-search-label'),
					$searchWrapperInput: $form.find('.js-search-wrapper-input'),
					$searchMessage: $form.find('.js-search-message')
				};

				if (this.config.quickSearch) {
					formObj.$searchResult = formObj.$form.find('.js-search-result');
					formObj.$searchResultList = formObj.$form.find('.js-search-result-list');
					formObj.$searchResultButton = formObj.$form.find('.js-search-result-button');
					this.initQuickSearch(formObj);
				}

				if (this.config.speechRecognition && $('html').hasClass('feature-speechrecognition')) {
					formObj.$searchSpeechButton = formObj.$form.find('.js-search-button-speech');

					this.initSpeechRecognition(formObj);
				}

				if (this.config.closeButton) {
					formObj.$searchCloseButton = formObj.$form.find('.js-search-button-close');

					formObj.$searchCloseButton.on('click', $.proxy(this, 'onCloseButton', formObj));
				}

				formObj.$searchButton.on('click', $.proxy(this, 'onClickButton', formObj));
				formObj.$searchInput
					.on('focus', $.proxy(this, 'onFocusInput', formObj))
					.on('blur', $.proxy(this, 'onBlurInput', formObj));
				formObj.$form.on('submit', $.proxy(this, 'onFormSubmit', formObj));
			});
		}
	}

	/**
	 * Callback на нажатие кнопки поиска
	 * @param {object} formObj объект формы
	 * @param {object} event событие клика
	 */
	onClickButton(formObj, event) {
		this.expandInput(formObj, event);
	}

	/**
	 * Callback на фокус поля ввода
	 * @param {object} formObj объект формы
	 * @param {object} event событие фокуса
	 */
	onFocusInput(formObj, event) {
		this.setFocusState(formObj, event);
	}

	/**
	 * Callback на расфокус поля ввода
	 * @param {object} formObj объект формы
	 * @param {object} event событие расфокуса
	 */
	onBlurInput(formObj, event) {
		this.setBlurState(formObj, event);
	}

	/**
	 * Раскрыть поле ввода
	 * @param {object} formObj объект формы
	 * @param {object} event событие фокуса
	 */
	expandInput(formObj, event) {
		const $this = $(event.currentTarget);

		if (!$this.hasClass('is-expand')) {
			event.preventDefault();

			$this.addClass('is-expand');
			formObj.$searchWrapperInput.velocity(this.config.animation.input.show.effect, {
				duration: this.config.animation.input.show.duration,
				complete: () => {
					$(document).on('click touchstart', $.proxy(this, 'onDocumentListener', formObj));
					formObj.$searchInput.focus();
				}
			});
		}
	}

	/**
	 * Скрыть поле ввода
	 * @param {object} formObj объект формы
	 */
	closeInput(formObj) {
		$(document).off('click touchstart', $.proxy(this, 'onDocumentListener'));

		formObj.$searchWrapperInput.velocity(this.config.animation.input.hide.effect, {
			duration: this.config.animation.input.hide.duration,
			complete: () => {
				formObj.$searchButton.removeClass('is-expand');
				formObj.$searchMessage.velocity('fadeOut', this.config.animation.input.hide.duration);

				if (this.config.cleanInputOnBlur) {
					formObj.$searchInput.val('');
					formObj.$searchLabel.velocity(this.config.animation.label.show.effect, this.config.animation.label.show.duration);
				}
			}
		});
	}

	/**
	 * На ввод текста в поле поиска при необходимой длинне запроса
	 * скрыть сообщение о коротком запросе и удалить слушателя события на ввод текста в поле поиска
	 * @param {object} formObj объект формы
	 */
	onInputTyping(formObj) {
		super.onInputTyping(formObj);
	}

	/**
	 * Обработчик события клика / тапа / наведения курсора на документ
	 * @param {string} formObj объект формы
	 * @param {object} event событие клика на документ
	 */
	onDocumentListener(formObj, event) {
		super.onDocumentListener(formObj, event);
	}
}

AR.waitComponents([], () => {
	const cSearchForm_extendable = new CSearchForm_extendable();
	// Вызов метода, инициализирующего все существующие события
	cSearchForm_extendable.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cSearchForm_extendable, 'cSearchForm_extendable');
});
