module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'tracking/base' && templateVars.config ?
				templateVars.config :
				{
	"printOnlyGoogle": true,
	"systems": {
		"ar": {
			"en": {
				"yandex": {
					"id": 57535435,
					"trackGoals": true,
					"userVisitedGoalID": "userVisited"
				},
				"google": {
					"id": "GTM-PHM5Q7K"
				}
			}
		},
		"csr": {
			"ru": {
				"yandex": {
					"id": 62115904,
					"trackGoals": true,
					"userVisitedGoalID": "userVisited"
				},
				"google": {
					"id": "GTM-TLLKR57"
				}
			},
			"en": {
				"yandex": {
					"id": 62115904,
					"trackGoals": true,
					"userVisitedGoalID": "userVisited"
				},
				"google": {
					"id": "GTM-TLLKR57"
				}
			}
		}
	}
};