import config from './config.yaml';
import addExcelExport from 'component/excel-export/.variant/base';
const text = require('./text.yaml')[templateVars.lang];
const {throttle} = require('throttle-debounce');
const isElementInView = require('component/core/.variant/base/additionals/isElementInView.js');

/**
 * Класс реализует логику варианта base для компонента tables
 */
class CTables_base {
	constructor() {
		this.maximumScaling = 6;
		this.expandClass = 'is-expand';
	}

	/**
	 * Инициализация
	 */
	init() {
		this.config = config;
		this.$tables = $('.b-table');
		this.$tableCaption = $('.b-table').find('th');
		this.$tableСell = $('.b-table').find('td');

		if (this.$tables.length) {
			$(document).ready(() => {
				this.processingTables(this.$tables);
			});
		}
	}

	/**
	 * Обработать таблицы
	 * Провесить слушателей событий, кнопки и т.п.
	 * @param {object} $tables таблицы
	 */
	processingTables($tables) {
		if (addExcelExport) {
			addExcelExport($tables, Boolean(this.config.noHideInZoom));
		}

		$tables.each((index, el) => {
			const $this = $(el);

			if (!$this.find('.figure-buttons.js-buttons').length) {
				$this.append('<div class="figure-buttons js-buttons"></div>');
			}

			// if (!$this.hasClass('b-table--no-xls') && addExcelExport) {
			// 	addExcelExport($this, Boolean(this.config.noHideInZoom));
			// }

			if (!$this.hasClass('b-table--no-zoom')) {
				AR.components.cZoomIn_base.addZoomIn($this);
			}

			if ($this.hasClass('b-table--fixed-scrollbar')) {
				this.stickyScroll($this);
				this.initScrollButtons($this);
			}

			if (this.config.selectableColumns && !$this.hasClass('b-table--no-select-column')) {
				this.onToggleClassColumns($this);
			}

			// жс-выделение для таблиц с rowspan
			this.initHandlersInprocess($this);
			//

			if (this.config.selectableRows) {
				$this.find('tr:not([data-rowspan-group]').on('click', $.proxy(this, 'onSelectedRow'));
			}

			this.scaleLargeTable($this);
			const isFixedHead = config.fixedHead && !$this.hasClass('b-table--no-fixed-head');

			AR.events.on('onFontSizeChanged', () => {
				this.scaleLargeTable($this, true);

				if (isFixedHead) {
					const $fixedHead = $this.find('.js-fixed-head');

					this.setFixedHeadSizes($this, $fixedHead);
				}
			});

			AR.events.on('onTableScaleSet', () => {
				if (isFixedHead) {
					const $fixedHead = $this.find('.js-fixed-head');

					this.setFixedHeadSizes($this, $fixedHead);
				}
			});

			if (isFixedHead) {
				this.addHeadForFixed($this);
			}

			//Параметр количества показываемых по умолчанию строчек
			let visibleRowsCount = $this.attr('data-visible-rows');
			if (typeof visibleRowsCount != 'undefined') {
				this.addToggleVisibleRowsButton($this, visibleRowsCount);
			}
		});

		if ($tables.length) {
			$(window).resize(() => {
				$tables.each((i, table) => {
					const $table = $(table);
					this.scaleLargeTable($table);
				});
			});

			if (this.config.fixedHead) {
				const $tablesWithFixedHead = $tables.filter((i, elem) => !$(elem).hasClass('b-table--no-fixed-head'));

				$(window).scroll(() => {
					$tablesWithFixedHead.each((i, table) => {
						const $table = $(table);
						this.fixedHead($table);
					});
				});

				$(window).resize(() => {
					$tablesWithFixedHead.each((i, table) => {
						const $table = $(table);
						this.fixedHead($table);
					});
				});
			}

			const self = this;

			AR.events.on('onTableZoomInComplete', (html) => {
				const $zoomHtml = $(html);
				const $fixedHeadTable = $zoomHtml.find('.js-fixed-head');
				const $tableColorBoxButton = $zoomHtml.find('.js-toggle-visible-rows');

				if ($fixedHeadTable.length) {
					$fixedHeadTable.remove();
				}

				if (this.config.selectableColumns) {
					const $tableZoomIn = $zoomHtml.find('.b-table');

					if (!$tableZoomIn.hasClass('b-table--no-select-column')) {
						this.onToggleClassColumns($tableZoomIn);
					}
				}

				if ($tableColorBoxButton.length) {
					$tableColorBoxButton.remove();
				}

				// жс-выделение для таблиц с rowspan
				this.initHandlersInprocess($zoomHtml.find('table'));
				//

				if (this.config.selectableRows) {
					const $tableColorBox = $zoomHtml.find('table');

					$tableColorBox.find('tr:not([data-rowspan-group]').on('click', (e) => {
						self.onSelectedRow(e);
					});
				}
			});
		}
	}

	/**
	 * Инициализация обработчиков выделения строк с атрибутом rowspan
	 * @param {object} $table таблица
	 */
	initHandlersInprocess($table) {
		if ($table.find('td[rowspan]').length && this.config.selectableRows) {
			const trCollection = Array.from($table.find('tbody tr'));
			const rowspanCollection = trCollection.filter(tr => {
				return tr.querySelector('td[rowspan]');
			});

			rowspanCollection.forEach((row, i) => {
				let id = $(row).index();
				// let rowspan = parseInt(row.querySelector('td[rowspan]').getAttribute('rowspan'));
				let getRowspanValue = () => {
					let max = [...row.querySelectorAll('td[rowspan]')].sort((a, b) => {
						return parseInt(a.getAttribute('rowspan')) > parseInt(b.getAttribute('rowspan')) ? -1 : 1;
					});
					// parseInt(row.querySelector('td[rowspan]').getAttribute('rowspan'));
					return parseInt(max[0].getAttribute('rowspan'));
				};

				let range = [...trCollection].splice(id, getRowspanValue());

				range.forEach(tr => {
					tr.dataset.rowspanGroup = i;
				});
			});

			trCollection.forEach(tr => {
				tr.addEventListener('click', $.proxy(this, 'additionalOnSelectedRow'));
				tr.addEventListener('mouseover', $.proxy(this, 'onHoverRow'));
				tr.addEventListener('mouseleave', $.proxy(this, 'offHoverRow'));
			});

		}
	}

	/**
	 * callback на клик по строке
	 * @param {object} e событие клика
	 */
	onSelectedRow(e) {
		const $item = $(e.currentTarget);

		if ($item.hasClass('b-selected-row')) {
			$item.removeClass('b-selected-row');
		} else {
			$item.addClass('b-selected-row');
		}
	}

	/**
	 * Дополнительные callback`и на выделение rowspan
	 * @param {object} e событие
	 */
	additionalOnSelectedRow(e) {
		const $item = $(e.currentTarget);

		if ($item.attr('data-rowspan-group')) {
			$item.parent()
				.find(`tr[data-rowspan-group="${$item.attr('data-rowspan-group')}"]`)
				.toggleClass('b-selected-row');
		}
	}

	/**
	 * Провешивание js-го hover
	 * @param {object} e событие
	 */
	onHoverRow(e) {
		const target = e.currentTarget;

		if (target.dataset.rowspanGroup && !(target.classList.contains('is-hover'))) {
			let group = $(target).parent().find(`tr[data-rowspan-group="${target.dataset.rowspanGroup}"]`);

			group.addClass('is-hover');
		}
	}

	/**
	 * Удаление js-го hover
	 * @param {object} e событие
	 */
	offHoverRow(e) {
		const target = e.currentTarget;

		if (target.dataset.rowspanGroup) {
			let group = $(target).parent().find(`tr[data-rowspan-group="${target.dataset.rowspanGroup}"]`);

			group.removeClass('is-hover');
		}
	}

	/**
	 * Масштабирование больших таблиц
	 * @param {object} $tableWrapper таблица
	 * @param {boolean} fontSizeChanged метод вызван по событию смены размера шрифта
	 * @param {number} prevWidth ширина таблицы на предыдущем уровне масштабирования
	 */
	scaleLargeTable($tableWrapper, fontSizeChanged = false, prevWidth = 0) {
		let contentWidth = $('#content-area').width();
		const $table = $tableWrapper.find('table');

		if ((!$tableWrapper.hasClass('b-table--scale') || fontSizeChanged) && !$tableWrapper.hasClass('b-table--no-scale')) {
			// влезает ли таблица в контейнер
			if ($table.width() > contentWidth) { //нет
				let scaleLevel = $tableWrapper.attr('data-scale-level');

				//есть ли у таблицы множитель масштабирования?
				if (!scaleLevel) { //нет
					scaleLevel = 1;
				}
				//флаг того, что множитель - максимально возможный
				// let isItMaximumPossibleScaleCoeff = false;
				let newScaleLevel = Number(scaleLevel);

				// является ли этот множитель минимально возможным
				if (scaleLevel < 6) { //да
					//увеличиваем множитель на единицу
					newScaleLevel = Number(newScaleLevel) + 1;

					$tableWrapper
						.removeClassWild('b-table--auto-scale-')
						.addClass(`b-table--auto-scale-${newScaleLevel}`);

					$tableWrapper.attr('data-scale-level', newScaleLevel);
				}

				// влезает ли таблица в контейнер
				if (!($table.width() <= contentWidth) && $table.width() !== prevWidth) { //нет
					// перевызов этой функции
					this.scaleLargeTable($tableWrapper, false, $table.width());
				} else {
					AR.events.emit('onTableScaleSet', ($tableWrapper));
				}
			} else { //да
				let scaleLevel = Number($tableWrapper.attr('data-scale-level'));

				//есть ли у таблицы множитель масштабирования?
				if (!scaleLevel) { //нет
					return;
				}

				//флаг того, что множитель - минимально возможный
				let isItMinimumPossibleScaleCoeff = false;
				let newScaleLevel = Number(scaleLevel);

				// является ли этот множитель максимально возможным
				if (scaleLevel == 1) { //да
					isItMinimumPossibleScaleCoeff = true;

					//убираем scale
					$tableWrapper
						.removeClassWild('b-table--auto-scale-');

					$tableWrapper.attr('data-scale-level', 0);
				} else {
					//увеличиваем множитель на единицу
					newScaleLevel = newScaleLevel - 1;

					$tableWrapper
						.removeClassWild('b-table--auto-scale-')
						.addClass(`b-table--auto-scale-${newScaleLevel}`);

					$tableWrapper.attr('data-scale-level', newScaleLevel);
				}

				// влезает ли таблица в контейнер
				if ($table.width() <= contentWidth) { //да
					// перевызов этой функции
					this.scaleLargeTable($tableWrapper, false, $table.width());
				} else { //нет
					//возвращаем как было

					if (isItMinimumPossibleScaleCoeff) {
						//убираем scale
						$tableWrapper
							.removeClassWild('b-table--auto-scale-')
							.addClass('b-table--auto-scale-1');

						$tableWrapper.attr('data-scale-level', 1);
					} else {
						$tableWrapper
							.removeClassWild('b-table--auto-scale-')
							.addClass(`b-table--auto-scale-${scaleLevel}`);

						$tableWrapper.attr('data-scale-level', scaleLevel);
					}
				}
			}
		}
	}

	/**
	 * Фиксирование шапки таблицы
	 * @param {object} $table таблица
	 */
	fixedHead($table) {
		const $fixedTable = $table.find('.js-fixed-head');
		const $figureButtons = $table.find('.js-buttons');
		const headHeight = $fixedTable.height();
		const figureButtonsHeight = $figureButtons.length ? $figureButtons.outerHeight(true) : 0;
		const tableTop = $table.offset().top;
		const tableBot = $table.offset().top + $table.height() - headHeight - figureButtonsHeight;
		const scrollTop = $(window).scrollTop();

		$fixedTable.css('width', $table.width() + 'px');
		// if (scrollTop > tableTop && scrollTop < tableBot && $fixedTable.is(':hidden')) {
		if (scrollTop > tableTop && scrollTop < tableBot && $fixedTable.css('visibility') == 'hidden') {
			$fixedTable.css({
				visibility: 'visible',
				left: 'auto'
			});
			// } else if ($fixedTable.is(':visible') && !(scrollTop > tableTop && scrollTop < tableBot)) {
		} else if ($fixedTable.css('visibility') == 'visible' && !(scrollTop > tableTop && scrollTop < tableBot)) {
			// $fixedTable.hide();
			$fixedTable.css({
				visibility: 'hidden',
				left: '-99999px'
			});
		}
	}

	/**
	 * Добавление скрытой шапки, которая будет фиксироваться
	 * @param {object} $table таблица
	 */
	addHeadForFixed($table) {
		const captionText = $table.find('figcaption').html();
		const tableCaption = captionText ? `<figcaption>${captionText}</figcaption>` : '';
		const tableHead = $table.find('thead').html();

		$table.append(`<div class="b-fixed-head js-fixed-head"><table class="b-fixed-head__table">${tableCaption}<thead>${tableHead}</thead></table></div>`);

		const $fixedHead = $table.find('.js-fixed-head');
		const $fixedCells = $fixedHead.find('th');

		if (this.config.selectableColumns && !$table.hasClass('b-table--no-select-column')) {
			$fixedCells.on('click', $.proxy(this, 'toggleClassColumns'));
		}

		this.setFixedHeadSizes($table, $fixedHead);

		$(window).on('resize', throttle(50, () => this.setFixedHeadSizes($table, $fixedHead)));
	}

	/**
	 * Задать размеры ячеек фиксированой шапки
	 * @param {object} $table таблица
	 * @param {object} $fixedHead фиксированая шапка
	 */
	setFixedHeadSizes($table, $fixedHead) {
		const tableContainerWidth = $table.width();
		const tableWidth = $table.find('table').width();
		const $tableHeadCells = $table.find('thead th');
		const $fixedHeadTable = $fixedHead.find('.b-fixed-head__table');
		const $fixedHeadCells = $fixedHead.find('th');

		$fixedHead.width(tableContainerWidth);
		$fixedHeadTable.width(tableWidth);

		$tableHeadCells.each(function (i) {
			$fixedHeadCells.eq(i).width($(this).width());
		});
	}

	/**
	 * Показ всех строчек таблицы
	 * @param $rows - строчки для показа
	 * @param button - кнопка-тогл
	 */
	showRows($rows, button) {
		//показыаем все строки
		$rows.show();
		//добавляем класс раскрытия
		button.addClass(this.expandClass);
		//меняем текст на тогле
		button.html(text.hideAllRowsButton);
	}

	/**
	 * Скрытие строчек в таблице
	 * @param $rows - строки таблицы
	 * @param visibleRows - количество строчек, которые нужно оставить показанным
	 * @param button - кнопка-тогл
	 * @param scrollToTable - нужно ли скролить к таблице
	 */
	hideRows($rows, visibleRows, button, scrollToTable = true) {
		//скрываем все строки, кроме минимального количества видимых
		$rows
			.not($rows.slice(0, visibleRows))
			.hide();

		//удаляем классы раскрытия
		button.removeClass(this.expandClass);
		//меняем текст на тогле
		button.html(text.showAllRowsButton);

		if (scrollToTable) {
			//скролим к таблице
			$('html,body').animate({scrollTop: button.closest('.b-table').offset().top}, 'slow');
		}
	}

	/**
	 * Переключение состоянии таблицы - показывать переданные строки или скрывать
	 * @param $rows - строки
	 * @param visibleRows - количество, которое нужно оставлять показанным
	 * @param button - кнопка-тогл
	 */
	toggleVisibleRows($rows, visibleRows, button) {
		//Если у обработчика есть класс раскрытия, т.е. блок нужно сворачивать
		if (button.hasClass(this.expandClass)) {
			this.hideRows($rows, visibleRows, button);
		} else {
			this.showRows($rows, button);
		}
	}

	/**
	 * Метод добавляет кнопку показа-скрытия дополнительных строк в таблице
	 * @param $table - контейнер таблицы
	 * @param visibleRows - количество строчек, который нужно оставлять раскрытыми
	 */
	addToggleVisibleRowsButton($table, visibleRows) {
		let $toggle = $table.find('.js-toggle-visible-rows'); //Пытаемся найти элемент с созданной ранее кнопкой
		let $rows = $table.find('tbody>tr'); //Получаем все строчки в tbody

		//Количество найденных строк больше, чем минимально показываемое количество?
		if ($rows.length > visibleRows) {
			//Была ли добавлена ранее кнопка?
			if ($toggle.length) {
				return false;
			} else {
				//Создаем элемент кнопки
				let button = $('<button class="b-table__toggle-visible-rows-trigger js-toggle-visible-rows"/>');
				//Прописываем название кнопки
				button.html(text.showAllRowsButton);
				//Добавляем кнопку в элеменет таблицы
				$table.append(button);

				//Создаем обработчик события клик
				button.on('click', $.proxy(this, 'toggleVisibleRows', $rows, visibleRows, button));

				//скрываем строки посли подгрузки кнопки
				this.hideRows($rows, visibleRows, button, false);
			}
		}
	}

	/**
	 * Добавить фиксирующийся скрол
	 * @param {object} $table
	 */
	stickyScroll($table) {
		const $scrollBarContainer = $table.find('.b-table__content');
		const fixedBarTemplate = '<div class="b-fixed-scrollbar"><div>';
		const pageNavHeight = $('.b-pagenav--bottom').length ? `${($('.b-pagenav--bottom').height() - 1) / parseFloat($('body').css('font-size'))}rem` : '-1px';
		const scrollBarBottomMargin = pageNavHeight;
		const $scrollBar = $(fixedBarTemplate).appendTo($scrollBarContainer).css('bottom', scrollBarBottomMargin);


		//Прокрутка основного скролла при скролле зафиксированого
		$scrollBar.scroll(function () {
			$scrollBarContainer.scrollLeft($scrollBar.scrollLeft());
		});

		$scrollBar.data('state', 'hidden');

		//Установка размеров зафиксированого скролла
		const fixSize = () => {
			let scrollBarWidth = $scrollBarContainer.width();

			$scrollBar.children('div').height(1).width($scrollBarContainer[0].scrollWidth);
			$scrollBar.width(scrollBarWidth).scrollLeft($scrollBarContainer.scrollLeft());
		};

		//Прикрепить, открепить/показать, скрыть зафиксированый скролл
		const stickIt = () => {
			const containerOffset = {top: $scrollBarContainer.offset().top + $table.find('th').outerHeight() + $scrollBar.height(), bottom: $scrollBarContainer.offset().top + $scrollBarContainer.height()};
			const offsetPageNavHeight = $('.b-pagenav--bottom').length ? $('.b-pagenav--bottom').height() - 1 : 0;
			const windowOffset = {top: $(window).scrollTop(), bottom: $(window).scrollTop() + $(window).height() - offsetPageNavHeight};

			//Скрыть скролл
			if ((containerOffset.top > windowOffset.bottom) || (windowOffset.bottom > containerOffset.bottom)) {
				if ($scrollBar.data('state') == 'visible') {
					$scrollBar.hide().data('state', 'hidden');
				}
			} else { //Показать скролл
				if ($scrollBar.data('state') == 'hidden') {
					$scrollBar.show().data('state', 'visible');
					$scrollBar.scrollLeft($scrollBarContainer.scrollLeft());
				}
			}
		};

		fixSize();
		stickIt();
		$(window).on('scroll', stickIt);
	}

	/**
	 * Инициализировать работу кастомного скролла, стрелок
	 * @param {object} $table таблица
	 */
	initScrollButtons($table) {
		const $containerWrapper = $table.find('.b-table__content-wrapper');
		const $container = $table.find('.b-table__content');
		const $scrollBar = $table.find('.b-fixed-scrollbar');
		const scrollBounds = {min: 0, max: $container[0].scrollWidth - $container[0].clientWidth};
		let scrollIt = true;

		//Проверка на существаоние кнопок в таблице
		if (!$table.find('.b-scroll-arrow').length) {
			$containerWrapper.append(`
				<div class="b-scroll-arrow b-scroll-arrow--left disabled" data-direction-sign="-">
					<span class="b-scroll-arrow__icon"></span>
				</div>
				<div class="b-scroll-arrow b-scroll-arrow--right" data-direction-sign="+">
					<span class="b-scroll-arrow__icon"></span>
				</div>
				`);
		}

		//Установить положение стрелок кнопок по вертикали
		this.setArrowsPosition($table);

		const arrowLeft = $table
			.find('.b-scroll-arrow--left')
			.addClass('disabled');
		const arrowRight = $table
			.find('.b-scroll-arrow--right')
			.removeClass('disabled');
		const $arrows = $table
			.find('.b-scroll-arrow')
			.addClass('loaded');

		//Производить скролл по нажатию
		$arrows.on('mousedown', function () {
			scrollIt = true;
			scrollStep($(this));
		});

		//Ставить флаг, что требуется остановить скролл при отпускании кнопки
		$arrows.on('mouseup', function () {
			scrollIt = false;
		});

		$(window).on('keydown', (e) => {
			if (e.keyCode === 39) {
				const arrowRightButton = $scrollBar.scrollLeft() + 10;

				if (isElementInView($scrollBar)) {
					$scrollBar.scrollLeft(arrowRightButton);
				}
			}

			if (e.keyCode === 37) {
				const arrowLeftButton = $scrollBar.scrollLeft() - 10;

				if (isElementInView($scrollBar)) {
					$scrollBar.scrollLeft(arrowLeftButton);
				}
			}
		});

		$container.on('scroll', function () {
			const scrollValue = $container.scrollLeft();
			$container.closest('.b-table--fixed-scrollbar').find('.js-fixed-head').scrollLeft(scrollValue);

			//Активация и деактивация кнопок
			if (scrollBounds.min == scrollValue) {
				arrowLeft.addClass('disabled');
				arrowRight.removeClass('disabled');
			} else if (scrollBounds.max == scrollValue) {
				arrowRight.addClass('disabled');
				arrowLeft.removeClass('disabled');
			} else if (scrollBounds.max == 0) {
				$arrows.addClass('disabled');
			} else {
				$arrows.removeClass('disabled');
			}
		});

		//Перерасчитывать положение стрелок кнопок при скролле и ресайзе
		$(window).on('scroll resize', () => {
			this.setArrowsPosition($table);
		});

		//Выполнение скролла
		const scrollStep = ($elem) => {
			//Проверка на то, что кнопка активна
			if (!$elem.hasClass('disabled')) {
				const directionSign = $elem.data('directionSign');
				const $scrollEl = $scrollBar.is(':visible') ? $scrollBar : $container;
				const scrollValue = $scrollEl.scrollLeft() + Number(`${directionSign}20`);

				$scrollEl.animate({
					scrollLeft: scrollValue,

				}, 5, () => {
					if (scrollIt) {
						scrollStep($elem);
					}
				});
			}
		};
	}

	/**
	 * Задать положение стрелок скрола
	 * @param {object} $table таблица
	 */
	setArrowsPosition($table) {
		const $element = $table.find('.b-table__content');
		const elementOffset = $element.offset();
		const $window = $(window);
		const pageTop = $window.scrollTop();
		const pageBottom = pageTop + $window.height();
		const elementTop = elementOffset.top;
		const elementBottom = elementTop + $element.outerHeight();

		//Если таблица в зоне видимости окна
		if ((pageTop < elementBottom) && (pageBottom > elementTop)) {
			const $arrows = $table.find('.b-scroll-arrow__icon');

			const arrowsHeight = $arrows.height();
			const scrollBarHeight = $('.b-fixed-scrollbar').height();
			const pagenavHeight = $('.b-pagenav--bottom').length ? $('.b-pagenav--bottom').height() : 0;
			//Высота прикрепляющихся снизу окна элементов
			const controlersHeight = pagenavHeight + scrollBarHeight;
			const tableHeight = $element.height();
			// Расстояние от верхней границы таблицы до верхней границы окна
			const topSpace = elementTop - pageTop > 0 ? elementTop - pageTop : 0;
			// Расстояние от нижней границы таблицы до нижней границы окна
			const botSpace = (pageTop + $window.height()) - (elementBottom + pagenavHeight) > 0 ? (pageTop + $window.height()) - elementBottom : 0;
			//Высота от верхней границы окна до верхней границы таблицы
			const extraTopSpace = pageTop - elementTop > 0 ? pageTop - elementTop : 0;
			// Высота фиксирующихся снизу элементов
			const extraBotSpace = !botSpace ? controlersHeight : $('.b-fixed-scrollbar').height();
			const theadHeight = $table.find('thead').height();
			// Высота таблицы, которая находится в зоне видимости окна
			const inViewTableHeight = $window.height() - topSpace - botSpace - extraBotSpace;

			//Если таблица не в зоне видимости, то скрыть стрелки
			if (topSpace && inViewTableHeight <= theadHeight
				|| botSpace && inViewTableHeight <= arrowsHeight + scrollBarHeight) {
				if ($arrows.hasClass('is-visible')) {
					$arrows
						.removeClass('is-visible')
						.velocity('fadeOut', 150, 'ease');
				}
			} else { //Иначе показать стрелки и установить положение по вертикали
				const arrowsPosition = inViewTableHeight / 2 + extraTopSpace;

				$arrows.css('top', arrowsPosition);

				if (!$arrows.hasClass('is-visible')) {
					$arrows
						.addClass('is-visible')
						.velocity('fadeIn', 150, 'ease');
				}
			}
		}
	}

	/**
	 * Провесить / убрать классы выделения столбца с ячеек
	 * @param {object} event событие
	 */
	toggleClassColumns(event) {
		const $tdIndex = $(event.currentTarget).index();
		const $trTable = $(event.currentTarget).closest('.b-table').find('tr');

		$trTable.each((i, elem) => {
			const $selectCell = $('td:eq(' + $tdIndex + ')', elem);

			if (event.type === 'click') {
				$selectCell.toggleClass('is-active');
			} else {
				$selectCell.toggleClass('is-hover');
			}
		});
	}

	/**
	 * Провесить обработчики смены классов ячеек для выделения столбца
	 * @param {object} $table таблица
	 */
	onToggleClassColumns($table) {
		$table.find('td').on('mouseenter mouseleave', $.proxy(this, 'toggleClassColumns'));
		$table.find('th').on('mouseenter mouseleave click', $.proxy(this, 'toggleClassColumns'));
	}
}

AR.waitComponents(['cZoomIn_base'], () => {
	const cTables_base = new CTables_base();
	// Вызов метода со всеми событиями
	cTables_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cTables_base, 'cTables_base');
});
