module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'key-indicator/animated' && templateVars.config ?
				templateVars.config :
				{
	"ru": {
		"mask": "0,.[000]",
		"animationSpeed": "4000,",
		"fadeSpeed": 300,
		"local": {
			"delimiters": {
				"thousands": " ",
				"decimal": ","
			},
			"abbreviations": {
				"thousand": "k",
				"million": "m",
				"billion": "b",
				"trillion": "t"
			},
			"currency": {
				"symbol": "ru"
			}
		}
	},
	"en": {
		"mask": "0,.[000]",
		"animationSpeed": "4000,",
		"fadeSpeed": 300,
		"local": {
			"delimiters": {
				"thousands": ",",
				"decimal": "."
			},
			"abbreviations": {
				"thousand": "k",
				"million": "m",
				"billion": "b",
				"trillion": "t"
			},
			"currency": {
				"symbol": "en"
			}
		}
	}
};