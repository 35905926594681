module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'page-nav/bottom' && templateVars.config ?
				templateVars.config :
				{
	"titleParam": "shortTitle",
	"deactivateBtn": "deactivate",
	"hideUpBtn": false,
	"scrollTopValue": 200,
	"removeUpBtnOnScroll": true,
	"buttonWithText": true,
	"hotkeyWithText": true,
	"customTooltip": false,
	"tooltipWithText": true,
	"animateLinks": false,
	"expandableButton": false,
	"setParentHeight": true,
	"defaultAsStatic": true,
	"showAnimationSettings": {
		"props": {
			"bottom": 0
		},
		"options": {
			"duration": 250,
			"easing": "ease"
		}
	}
};